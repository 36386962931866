import Success from "~icons/icons/Success.svg"
import Warning from "~icons/icons/Warning.svg"
import Danger from "~icons/icons/Danger.svg"

export type IconType = keyof typeof iconsMap

export const iconsMap = {
  success: Success,
  warning: Warning,
  danger: Danger
}
